import { combineReducers } from "redux";
import myCatalogSlice from "../slices/myCatalogSlice";
import uploadsSlice from "../slices/uploadsSlice";
import moderatorCommentsSlice from "../slices/moderatorCommentsSlice";
import tempDocumentsSlice from "../slices/tempDocumentsSlice";
import importTaskSlice from "../slices/importTaskSlice";
import warehousesSlice from "../slices/warehousesSlice";

const mainReducer = combineReducers({
  myCatalogSlice,
  uploadsSlice,
  moderatorCommentsSlice,
  tempDocumentsSlice,
  importTaskSlice,
  warehousesSlice,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
