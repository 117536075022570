import { useCallback, useState, useEffect, useRef } from "react";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { debounce } from "lodash";

interface ParamsType {
  [key: string]: any;
}

interface IUseCancelableDebounce {
  debouncedFunction: (params: ParamsType) => void;
  cancelToken: CancelToken;
  cancelRequest: (message: string) => void;
}

const useCancelableDebounce = (
  callback: (params: ParamsType, token: CancelToken) => void,
  delay: number = 500
): IUseCancelableDebounce => {
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(null);

  const cancelRequest = useCallback((message: string): void => {
    cancelTokenSource && cancelTokenSource.cancel(message);
  }, [cancelTokenSource]);

  const debouncedCallback = useRef(
    debounce((params: ParamsType) => {
      cancelRequest("Request canceled");

      const newCancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      callback(params, newCancelTokenSource.token);
    }, delay)
  ).current;

  useEffect(() => {
    return () => {
      cancelRequest("Request canceled on cleanup");
    };
  }, [cancelRequest]);

  return {
    debouncedFunction: debouncedCallback,
    cancelToken: cancelTokenSource?.token,
    cancelRequest,
  };
};

export default useCancelableDebounce;