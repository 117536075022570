import * as TempDocumentsAPI from "../../api/tempDocuments";
import { ThunkAction } from "redux-thunk";
import { toCamelCase } from "../../utils/formatData";

import {
  createTempDocumentRequest,
  createTempDocumentSuccess,
  createTempDocumentFailure,

  fetchTempDocumentRequest,
  fetchTempDocumentSuccess,
  fetchTempDocumentFailure,
} from "../slices/tempDocumentsSlice";

import { UploadFile } from "antd";
import { AppStateType } from "../reducers/mainReducer";
import { CancelToken } from "axios";
import { TempDocumentType } from "tempDocuments/types";

{/**** Временный документ ****/ }

/** Получение временного документа */
export const fetchTempDocument = (
  documentId: string,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<TempDocumentType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchTempDocumentRequest(documentId));
  try {
    const response = await TempDocumentsAPI.getTempDocument(documentId, cancelToken);
    const formattedResponse: TempDocumentType = toCamelCase(response?.data);

    dispatch(fetchTempDocumentSuccess(formattedResponse));

    return Promise.resolve(formattedResponse);
  } catch (error: any) {
    dispatch(fetchTempDocumentFailure(error));

    return Promise.reject(error);
  }
};

/** Создание временного документа */
export const createTempDocument = (
  file: File | UploadFile,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<TempDocumentType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createTempDocumentRequest(file));
  try {
    const response = await TempDocumentsAPI.postTempDocument(file, cancelToken);
    const formattedResponse: TempDocumentType = toCamelCase(response);

    dispatch(createTempDocumentSuccess(formattedResponse));

    return Promise.resolve(formattedResponse);
  } catch (error: any) {
    dispatch(createTempDocumentFailure(error));

    return Promise.reject(error);
  }
};
