import React, { FC, useState, useEffect } from "react";

import {
  FormInstance,
  Form,
} from "antd";
import PanelLayout, { IPanelLayoutProps } from "../../../components/ui/Panel/PanelLayout";
import PanelBlock from "../../../components/ui/Panel/PanelBlock";
import FileUploadButton from "../../../components/FileUploadButton";

import { TempDocumentType } from "tempDocuments/types";

interface IImportExcelProps {
  form: FormInstance<any>;
}

const ImportExcel: FC<IImportExcelProps> = ({ form }) => {
  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Импорт таблицы Excel" },
    description: {
      content: <>Добавьте товары, используя прейскурант в Excel (XLS, CSV),
        после успешной загрузки она появится в списке задач по импорту.</>
    },
  };

  const [uploadFileData, setUploadFileData] = useState<TempDocumentType>(null);

  useEffect(() => {
    form && uploadFileData && form.setFieldValue("file", uploadFileData);
  }, [form, uploadFileData]);

  return (
    <PanelLayout {...PANEL_LAYOUT_PROPS}>
      <Form className="flex" form={form}>
        <PanelBlock title={{ content: "Загрузка файла" }}>
          <Form.Item className="m-0" name="fileField">
            <FileUploadButton
              acceptType=".xls, .xlsx, .csv"
              description="Формат: XLS, CSV"
              setUploadedFileData={setUploadFileData}
            />
          </Form.Item>
        </PanelBlock>
      </Form>
    </PanelLayout>
  );
};

export default ImportExcel;