import { createSlice } from "@reduxjs/toolkit";
import { UploadAutoType, UploadManualType } from "app/types";

export type InitialUploadsStateType = {
  total: number;
  page: number;
  pageSize: number;
  uploadsAutoList: UploadAutoType[] | null;
  uploadsManualList: UploadManualType[] | null;
  error: string | null;
  isFetching: boolean;
};

const initialState: InitialUploadsStateType = {
  total: 0,
  page: 1,
  pageSize: 10,
  uploadsAutoList: null,
  uploadsManualList: null,
  error: null,
  isFetching: false,
};

const uploadsSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    // Получение списка периодических задач
    fetchUploadsAutoRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchUploadsAutoSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.uploadsAutoList = action.payload.results;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    fetchUploadsAutoFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Удаление периодической задачи
    deleteUploadsAutoItemRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    deleteUploadsAutoItemSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.uploadsAutoList = state.uploadsAutoList?.filter(({id}) => id !== action.payload);
    },
    deleteUploadsAutoItemFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Приостановка или возобновление периодической задачи
    setPauseOrResumeUploadsAutoItemRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    setPauseOrResumeUploadsAutoItemSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.uploadsAutoList = state.uploadsAutoList?.map((item: UploadAutoType) => {
        if (item.id === action.payload.id) {
          // TODO в дальнейшем попросить бэк возвращать актуальные значения
          const status: "Остановлено" | "Новый" = action.payload.status === "pause" ? "Остановлено" : "Новый";

          return {...item, status};
        }
         return item;
      });
    },
    setPauseOrResumeUploadsAutoItemFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Получение списка разовых загрузок
    fetchUploadsManualRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchUploadsManualSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.uploadsManualList = action.payload.results;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    fetchUploadsManualFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
    // Удаление разовой загрузки
    deleteUploadsManualItemRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    deleteUploadsManualItemSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.uploadsManualList = state.uploadsManualList?.filter(({id}) => id !== action.payload);
    },
    deleteUploadsManualItemFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = uploadsSlice;

export const {
  deleteUploadsAutoItemRequest,
  deleteUploadsAutoItemSuccess,
  deleteUploadsAutoItemFailure,
  setPauseOrResumeUploadsAutoItemRequest,
  setPauseOrResumeUploadsAutoItemSuccess,
  setPauseOrResumeUploadsAutoItemFailure,
  deleteUploadsManualItemRequest,
  deleteUploadsManualItemSuccess,
  deleteUploadsManualItemFailure,
} = actions;

export default reducer;
