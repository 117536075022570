import React, { JSX, FC } from "react";
import { NavigateFunction, useNavigate } from "react-router";

// components
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import TabsWithCounters from "../../components/Catalog/TabsWithCounters/TabsWithCounters";
import { APP_PATHES } from "../../constants/appPathes";

interface ICatalogPageProps {}

const CatalogPage: FC<ICatalogPageProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const renderUploadProductButton = (): JSX.Element => {
    const handleOnClick = (): void => {
      navigate(APP_PATHES.IMPORT_MASTER);
    };

    return (
      <PrimaryButton onClickHandler={handleOnClick} text="Загрузить товар" />
    );
  };
  
  const renderHeader = (): JSX.Element => {
    return (
      <div className={`pr-6 pl-10 pt-8 sticky bg-white transition-all ease-in-out z-10`}>
        <header className="flex justify-between">
          <h3 className="mb-3">Мой каталог</h3>
          {renderUploadProductButton()}
        </header>
      </div>
    );
  };

  return (
    <div>
      {renderHeader()}
      <TabsWithCounters />
    </div>
  );
};

export default CatalogPage;
