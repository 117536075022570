import React, { JSX, FC, useState, useEffect } from "react";
import { RequestPaginationType, UploadAutoType, UploadManualType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import generateSortString from "../../../utils/generateSortString";
import { fetchAllImportTasks } from "../../../redux/thunks/importTask.thunk";
import { AppDispatch } from "../../../redux/store/store";
import { InitialImportTaskStateType } from "../../../redux/slices/importTaskSlice";

// components
import { Dropdown, FormInstance, MenuProps, Segmented } from "antd";
import CatalogTable, { CatalogColumnType } from "../CatalogTable/CatalogTable";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";

// icons
import { ReactComponent as MenuIcon } from "../../../assets/icons/three_dots_icon.svg";
import { ReactComponent as LoupeIcon } from "../../../assets/icons/search-square-icon.svg";

interface IUploadingProductsTabProps {
  form: FormInstance;
}

// TODO: моки удалить, когда появятся ручки
const mocksAuto: UploadAutoType[] = [
  {
    id: "uuid1",
    created_at: "2024-08-01 12:00",
    updated_at: "2024-01-10 13:00",
    update_period: 6,
    status: "Ошибка",
    source: "YML",
    error: "Все очень плохо",
    link: "https://jira.vtbc.net/browse/BC-3604"
  },
  {
    id: "uuid2",
    created_at: "2024-01-01 12:00",
    updated_at: "2024-01-10 15:00",
    update_period: 6,
    status: "Успешно",
    source: "YML",
    error: null,
    link: "https://jira.vtbc.net/browse/BC-3606"
  },
  {
    id: "uuid3",
    created_at: "2024-01-01 12:00",
    updated_at: "2027-02-01 09:00",
    update_period: 6,
    status: "Загрузка",
    source: "YML",
    error: null,
    link: "https://jira.vtbc.net/browse/BC-3605"
  },
];
// TODO: моки удалить, когда появятся ручки
const mocksManual: UploadManualType[] = [
  {
    id: "uuid1",
    created_at: "2024-02-01 15:00",
    catalogue_type: "Бизнес Коннект",
    status: "Ошибка",
    source: "YML",
    error: "Первый товар плохой",
    file: {file_name: "Какой-то файлик.xlsx", url: "https://jira.vtbc.net/browse/BC-3604"}
  },
  {
    id: "uuid2",
    created_at: "2024-08-05 12:00",
    catalogue_type: "Бизнес Коннект",
    status: "Успешно",
    source: "YML",
    error: null,
    file: {file_name: "Еще какой-то файлик.xlsx", url: "https://jira.vtbc.net/browse/BC-3605"}
  },
  {
    id: "uuid3",
    created_at: "2024-01-01 12:00",
    catalogue_type: "Вендор Менеджмент",
    status: "Загрузка",
    source: "YML",
    error: null,
    file: {file_name: "Еще какой-то файлик.xlsx", url: "https://jira.vtbc.net/browse/BC-3606"}
  },
];

const importTasksColumns: CatalogColumnType[] = [
  "created_at", "updated_at", "periodicity",
  "status", "file_type", "file", "actions"
];
const sourcesColumns: CatalogColumnType[] = ["date_time", "catalogue_type", "status", "file_type", "file"];

const UploadingProductsTab: FC<IUploadingProductsTabProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const importTask: InitialImportTaskStateType = useSelector((state: AppStateType) => state.importTaskSlice);

  const [currentTable, setCurrentTable] = useState<"importTasks" | "sources">("importTasks");
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });

  useEffect(() => {
    if (!importTask?.list && !importTask?.isFetching) {
      dispatch(fetchAllImportTasks({ is_periodic: true, ...getRequestPagination() }));
    }
  }, []);

  const getRequestPagination = (): RequestPaginationType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
    ...(sorterInfo ? { order_by: generateSortString(sorterInfo) } : {}),
  });

  const renderEmptyBlock = (): JSX.Element => {
    return (
      <EmptyBlock
        icon={<LoupeIcon className="mb-4" />}
        style={{ height: "calc(100vh - 23rem)" }}
        title={currentTable === "importTasks" ? "Задач нет" : "Загрузок нет"}
        description={
          currentTable === "importTasks" ? (
            <>Создайте свою первую задачу <br/> на автообновление каталога</>
          ) : (
            <>Ранее вы не загружали каталоги, загрузите <br/> свой первый каталог</>
          )
        }
      />
    );
  };

  const renderUploadAutoActions = (entity: UploadAutoType, idx: number): React.ReactNode => {
    const isShowPause: boolean = entity.status !== "Остановлено"
      && entity.status !== "Успешно"
      && entity.status !== "Ошибка";

    const items: MenuProps["items"] = [
      {
        label: <a onClick={() => {}}>Редактировать</a>,
        key: `edit-${idx}`,
        disabled: true
      },
      {
        label: <a onClick={() => {}}>{isShowPause ? "Остановить" : "Запустить"}</a>,
        key: `pause-${idx}`,
        disabled: entity.status === "Успешно"
      },
      {
        label: <a onClick={() => {}} style={{color: "#FF4D4F"}}>Удалить</a>,
        key: `delete-${idx}`,
        disabled: true
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const renderTable = (): JSX.Element => {
    return (
      <CatalogTable
        columns={currentTable === "importTasks" ? importTasksColumns : sourcesColumns}
        list={importTask.list}
        count={importTask.count}
        isFetching={importTask?.isFetching}
        isShowEmptyBlock
        emptyBlock={renderEmptyBlock()}
        renderActions={renderUploadAutoActions}
        setPaginationInfo={setPaginationInfo}
      />
    );
  };

  const handleTabChange = (value: string): void => {
    dispatch(fetchAllImportTasks({ is_periodic: value === "importTasks", ...getRequestPagination() }))
      .then(() => {
        setCurrentTable(value as "sources" | "importTasks");
      });
  };

  return (
    <div>
      <Segmented
        options={[
          { label: "Автообновление", value: "importTasks" },
          { label: "Разовые загрузки", value: "sources" },
        ]}
        onChange={handleTabChange}
      />
      {renderTable()}
    </div>
  );
};

export default UploadingProductsTab;
