import React, { JSX } from "react";
import { ProductStatusType, UploadStatus } from "app/types";
import { EntityType } from "../CatalogTable";
import { ImportTaskType } from "importTask/types";

// components
import Column from "antd/es/table/Column";
import { Tag, Tooltip } from "antd";

type TagColorType = "processing" | "success" | "warning" | "default" | "error";

const tagColors: Record<ProductStatusType | UploadStatus, TagColorType> = {
  "Новый": "processing",
  "Успешно": "success",
  "Загрузка": "warning",
  "Остановлено": "default",
  "Ошибка": "error",
  published: "success",
  moderation: "warning",
  draft: "default",
  rejected: "error",
  error: "error",
  needs_update: "error",
};

const getTagColor = (tag: ProductStatusType | UploadStatus): TagColorType => tagColors[tag];

const myCatalogStatuses: {[key: string]: string} = {
  published: "Размещен",
  moderation: "На модерации",
  rejected: "Отклонен",
  needs_update: "Отмена",
  canceled_by_client: "Требует обновления",
  error: "Ошибка",
  draft: "Черновик",
};

const uploadStatuses: {[key: string]: string} = {
  New: "Новый",
  Uploading: "Загрузка...",
  Paused: "Остановлено",
  Success: "Успешно",
  Error: "Ошибка"
};

export const renderStatusColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Status"
      title={renderColumnTitle("Статус")}
      dataIndex="status"
      width={150}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: ProductStatusType, entity: EntityType) => {
        return (
          <Tooltip title={(entity as ImportTaskType)?.error ?? null}>
            <Tag
              color={getTagColor(value)}
              className="w-fit cursor-default"
            >
              {myCatalogStatuses[value] || value}
            </Tag>
          </Tooltip>
        );
      }}
    />
  );
};
