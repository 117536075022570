import React, { FC } from "react";

interface IPagePanelProps {
  className?: string;
  children?: React.ReactNode;
}

const PagePanel: FC<IPagePanelProps> = ({ className = "", children }) => {
  return (
    <div className={`panel_wrapper bg-white rounded p-5 mb-4 ${className}`}>
      {children}
    </div>
  );
};

export default PagePanel;