import React, { FC, useState, useEffect } from "react";

import {
  Radio,
  Checkbox,
  Input,
  Form,
  CheckboxOptionType,
  FormInstance,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import PanelLayout, { IPanelLayoutProps } from "../../../components/ui/Panel/PanelLayout";
import PanelBlock from "../../../components/ui/Panel/PanelBlock";
import FileUploadButton from "../../../components/FileUploadButton";

import { TempDocumentType } from "tempDocuments/types";
import { RadioChangeEvent } from "antd/lib/radio";

export const SETTINGS_TYPE = {
  isPeriodic: "is_periodic",
  updateRest: "rests",
  updatePrice: "prices",
  updateProperty: "attributes",
  clearProducts: "clear_existing",
  logIn: "log_in",
};

interface IImportYMLProps {
  form: FormInstance<any>;
  initialSettings?: any;
}

const ImportYML: FC<IImportYMLProps> = ({ form }) => {
  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Загрузка через YML" },
    description: {
      content: <>
        YML (Yandex Market Language) - это стандарт, разработанный Yandex
        для приема и публикации информации в базе данных Yandex.Market.
        <a
          className="blue-color pl-2"
          href="https://yandex.ru/support/partnermarket/export/yml.html"
          target="blank"
        >
          Подробнее
        </a>
      </>
    },
    className: "flex"
  };

  const [uploadMode, setUploadMode] = useState<"file" | "url">("file");
  const [uploadSettings, setUploadSettings] = useState<any[]>([]);
  const [uploadedFile, setUploadedFile] = useState<TempDocumentType>(null);

  useEffect(() => {
    !!uploadSettings && form.setFieldValue("settings", uploadSettings.map((value) => value));
  }, [form, uploadSettings]);

  const UploadViaFileBlock = (): JSX.Element => {
    return (
      <Form.Item className="m-0 w-full" name="fileField">
        <FileUploadButton
          acceptType=".yml"
          description="Формат: YML"
          setUploadedFileData={setUploadedFile}
        />
      </Form.Item>
    );
  };

  const UploadPeriodicBlock = (): JSX.Element => {
    return (
      <div className="flex flex-col gap-4 w-full">
        <div className="upload_wrapper">
          <Form.Item className="m-0" name="fileLink">
            <Input
              className="upload_input"
              placeholder="Ссылка на файл YML"
              suffix={<LinkOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
              size="middle"
            />
          </Form.Item>
          {uploadSettings.includes(SETTINGS_TYPE.isPeriodic) && (
            <p className="ghost">Файл загружается каждые 6 часов</p>
          )}
        </div>
        {uploadSettings.includes(SETTINGS_TYPE.logIn) && (
          <div className="auth_wrapper flex flex-col">
            <div className="header_wrapper">Авторизация для загрузки каталога YML</div>
            <div className="flex flex-col gap-2">
              <Form.Item className="m-0" name="login">
                <Input placeholder="Введите логин" size="middle" />
              </Form.Item>
              <Form.Item className="m-0" name="password">
                <Input
                  placeholder="Введите пароль"
                  type="password"
                  size="middle"
                />
              </Form.Item>
            </div>
          </div>
        )}
      </div>
    );
  };

  const FileUploadBlock = (): JSX.Element => {
    const onChangeHandler = (event: RadioChangeEvent): void => setUploadMode(event.target.value);

    return (
      <PanelBlock title={{ content: "Загрузка файла" }} className="gap-2">
        <Radio.Group
          onChange={onChangeHandler}
          value={uploadMode}
          defaultValue="file"
          name="uploadMode"
        >
          <Radio.Button value="file">Из файла</Radio.Button>
          <Radio.Button value="url">По ссылке</Radio.Button>
        </Radio.Group>

        {uploadMode === "file"
          ? <UploadViaFileBlock />
          : <UploadPeriodicBlock />
        }
      </PanelBlock>
    );
  };

  const SettingsBlock = (): JSX.Element => {
    const settingsList: CheckboxOptionType<any>[] = [
      {
        label: <p className="select-none">Периодичная загрузка</p>,
        value: SETTINGS_TYPE.isPeriodic,
        disabled: uploadMode != "url",
      },
      {
        label: <p className="select-none">Остатки</p>,
        value: SETTINGS_TYPE.updateRest,
      },
      {
        label: <p className="select-none">Цены</p>,
        value: SETTINGS_TYPE.updatePrice,
      },
      {
        label: <p className="select-none">Свойства</p>,
        value: SETTINGS_TYPE.updateProperty,
      },
      {
        label: <p className="select-none">Удалить ранее загруженные товары</p>,
        value: SETTINGS_TYPE.clearProducts,
      },
      {
        label: <p className="select-none">Авторизация для загрузки каталога YML</p>,
        value: SETTINGS_TYPE.logIn,
        disabled: uploadMode != "url",
      }
    ];

    return (
      <PanelBlock title={{ content: "Настройки" }}>
        <Form.Item className="m-0" name="settings">
          <Checkbox.Group
            options={settingsList}
            value={uploadSettings}
            onChange={setUploadSettings}
            className="flex flex-col"
          />
        </Form.Item>
      </PanelBlock>
    );
  };

  return (
    <Form form={form}>
      <PanelLayout {...PANEL_LAYOUT_PROPS}>
        <FileUploadBlock />
        <SettingsBlock />
      </PanelLayout>
    </Form>
  );
};

export default ImportYML;