import { createSlice } from "@reduxjs/toolkit";
import { TempDocumentType } from "tempDocuments/types";

export type InitialTempDocumentsStateType = {
  item: TempDocumentType | null;
  error: string | null;
  isFetching: boolean;
  isUploading: boolean;
};

const initialState: InitialTempDocumentsStateType = {
  item: null,
  error: null,
  isFetching: false,
  isUploading: false,
};

const tempDocumentsSlice = createSlice({
  name: "tempDocuments",
  initialState,
  reducers: {
    /** Создание временного документа */
    createTempDocumentRequest(state, _action) {
      state.item = null;
      state.error = null;
      state.isUploading = true;
    },
    createTempDocumentSuccess(state, action) {
      state.item = action.payload;
      state.error = null;
      state.isUploading = false;
    },
    createTempDocumentFailure(state, action) {
      state.item = null;
      state.error = action.payload;
      state.isUploading = false;
    },

    /** Получение временного документа */
    fetchTempDocumentRequest(state, _action) {
      state.item = null;
      state.error = null;
      state.isFetching = true;
    },
    fetchTempDocumentSuccess(state, action) {
      state.item = action.payload;
      state.error = null;
      state.isFetching = false;
    },
    fetchTempDocumentFailure(state, action) {
      state.item = null;
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = tempDocumentsSlice;

export const {
  createTempDocumentRequest,
  createTempDocumentSuccess,
  createTempDocumentFailure,

  fetchTempDocumentRequest,
  fetchTempDocumentSuccess,
  fetchTempDocumentFailure,
} = actions;

export default reducer;
