import React, { JSX, FC, Dispatch, ReactNode, Key, useState, useEffect } from "react";
import { MyCatalogProductType } from "app/types";
import { TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";

// components
import InfoCard from "../../../ui/InfoCard/InfoCard";
import GhostButton from "../../../ui/GhostButton/GhostButton";
import SearchFilterPanel from "../SearchFilterPanel/SearchFilterPanel";
import CatalogTable from "../CatalogTable/CatalogTable";
import ProductsTableStub from "../ProductsTableStub/ProductsTableStub";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";
import { Dropdown, MenuProps, Select } from "antd";

// icons
import { ReactComponent as MenuIcon } from "../../../assets/icons/three_dots_icon.svg";

import css from "./style.module.css";

interface IComparisonOfCategoriesTabProps {
  setActiveTab: Dispatch<React.SetStateAction<string>>;
}

const ComparisonOfCategoriesTab: FC<IComparisonOfCategoriesTabProps> = ({setActiveTab}): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<MyCatalogProductType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [isShowInfoCard, setIsShowInfoCard] = useState<boolean>(localStorage.getItem("comparisonInfo") !== "true");
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value) => value.key));
  }, [selectedRows]);

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows: MyCatalogProductType[]) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  // TODO: указать тип, когда появится описание АПИ
  const fetchCategories = (): void => {
    // TODO: запрос на список категорий
  };

  const renderControls = (): JSX.Element => {
    return (
      <div className="flex justify-between mb-3">
        <div>
          {!!selectedRows?.length && (
            <>
              <GhostButton
                className="mr-3"
                text="Удалить выбранные"
                onClickHandler={() => {}}
              />
              <GhostButton
                className="mr-3"
                text="Удалить привязку к категории"
                onClickHandler={() => {}}
              />
              <PrimaryButton
                className="mr-3"
                size="middle"
                text="Подтвердить привязку к категории"
              />
            </>
          )}
          <Select
            placeholder="По наличию привязки"
            allowClear
          />
        </div>
        {!!selectedRows?.length && (
          <PrimaryButton text="Авто. сопоставление категорий" size="middle" />
        )}
      </div>
    );
  };

  const renderActions = (_value: any, idx: number): ReactNode => {
    const items: MenuProps["items"] = [
      {
        label: (
          <a
            onClick={() => {}}
            style={{color: "#FF4D4F"}}
          >
            Удалить привязку <br/> к категории портала
          </a>
        ),
        key: `delete-comparison-${idx}`,
        disabled: true
      },
      {
        label: (
          <a
            onClick={() => {}}
            style={{color: "#FF4D4F"}}
          >
            Удалить категорию
          </a>
        ),
        key: `delete-${idx}`,
        disabled: true
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const hideInfoCard = (): void => {
    localStorage.setItem("comparisonInfo", "true");
    setIsShowInfoCard(false);
  };

  return (
    <div>
      {isShowInfoCard && (
        <InfoCard
          title="Что это такое и зачем нужно?"
          text="Сопоставление категорий поставщика с категориями портала Бизнес Коннект необходимо для того,
          чтобы товары поставщика отображались в соответствующим им разделам каталога."
          actionBtn={
            <GhostButton
              text="Понятно"
              onClickHandler={hideInfoCard}
              className={css.infoCardBtn}
            />
          }
        />
      )}
      <SearchFilterPanel
        placeholder="Найти по наименованию категории"
        onSearch={(value) => console.log(value)}
      />
      {renderControls()}
      <CatalogTable
        columns={[
          "supplier_category",
          "editable_bk_category",
          "products_count",
          "actions"
        ]}
        list={[]} // TODO: передавать актуальный список
        count={0}
        isShowEmptyBlock
        rowSelection={rowSelection}
        emptyBlock={<ProductsTableStub setActiveTab={setActiveTab} />}
        fetchList={fetchCategories}
        renderActions={renderActions}
        setPaginationInfo={setPaginationInfo}
      />
    </div>
  );
};

export default ComparisonOfCategoriesTab;
