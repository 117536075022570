import React, { FC, JSX, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";

// components
import MainLayout from "./components/MainLayout/MainLayout";
import CatalogPage from "./pages/CatalogPage";
import ImportMaster from "./pages/ImportMaster";
import MatchingTableColumnsPage from "./pages/MatchingTableColumnsPage";

const App: FC = (): JSX.Element => {
  const token: string = localStorage.getItem("authtoken");

  const navigate: NavigateFunction = useNavigate();
  const params = useLocation();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token, params.pathname]);

  return (
    <MainLayout>
      {token && (
        <Routes>
          <Route path="/import/:tab?" element={<CatalogPage />} />
          <Route path="/import-master/" element={<ImportMaster />} />
          <Route path="/import-master/excel/:taskId" element={<MatchingTableColumnsPage />} />
        </Routes>
      )}
    </MainLayout>
  );
};

export default App;
