import React, { JSX } from "react";
import Column from "antd/es/table/Column";

export const renderCatalogueTypeColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Catalogue_type"
      title={renderColumnTitle("Где загружено")}
      dataIndex="catalog"
      width={250}
      render={(value, _entity) => value}
    />
  );
};
