import React, { FC, useState, useEffect } from "react";
import { useWatch } from "antd/es/form/Form";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialImportTaskStateType } from "../../../redux/slices/importTaskSlice";
import { TaskColumnType } from "importTask/types";
import { SelectOptionType } from "app/types";

// components
import PanelLayout, { IPanelLayoutProps } from "../../ui/Panel/PanelLayout";
import PagePanel from "../../ui/Panel/PagePanel";
import { Checkbox, Form, FormInstance } from "antd";
import MatchingColumn from "../MatchingColumn/MatchingColumn";

export const mocksColumns: TaskColumnType[] = [
  {
    name: "Моя тестовая колонка 1",
    values: ["1 строка таблицы", "2 строка таблицы", "3 строка таблицы"]
  },
  {
    name: "Моя тестовая колонка 2",
    values: ["1 строка таблицы", "2 строка таблицы", "3 строка таблицы"]
  },
  {
    name: "Моя тестовая колонка 3",
    values: ["1 строка таблицы", "2 строка таблицы", "3 строка таблицы"]
  }
];

interface IMatchingColumnsBlockProps {
  form: FormInstance<any>;
}

const MatchingColumnsBlock: FC<IMatchingColumnsBlockProps> = ({ form }) => {
  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Сопоставление колонок" },
    description: { content: "Выберите типы для колонок опираясь на данные из загруженной таблицы представленные ниже" }
  };

  const values = useWatch([], form);

  const importTask: InitialImportTaskStateType = useSelector((state: AppStateType) => state.importTaskSlice);
  const { xls: { columns } } = importTask ?? {};

  const [selectOptions, setSelectOptions] = useState<SelectOptionType[]>(columns.list);

  useEffect(() => {
    if (values) {
      const columnTypes: string[] = Object.keys(values)
        ?.filter((key: string) => key.includes("columnType") && values[key] !== undefined)
        ?.map((key: string) => values[key]?.value);

      setSelectOptions(columns.list.filter(({ value }) => !columnTypes.includes(value as string)));
    }
  }, [values]);

  const handleCheck = (e: CheckboxChangeEvent): void => {
    form.setFieldsValue({ firstLineSkip: e.target.checked });
  };

  return (
    <PagePanel>
      <PanelLayout {...PANEL_LAYOUT_PROPS}>
        <Form.Item name="firstLineSkip">
          <Checkbox className="mb-5" onChange={handleCheck}>Пропустить первую строку</Checkbox>
        </Form.Item>
      </PanelLayout>
      {mocksColumns.map((column: TaskColumnType, index: number) => (
        <MatchingColumn
          key={index}
          form={form}
          column={column}
          index={index}
          selectOptions={selectOptions}
        />
      ))}
    </PagePanel>
  );
};

export default MatchingColumnsBlock;
