import React, { FC, JSX } from "react";
import { useWatch } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import { postXLSConfig } from "../../api/importTask";
import { InitialImportTaskStateType } from "../../redux/slices/importTaskSlice";
import { useSelector } from "react-redux";

// components
import { Button, Form, Input, message } from "antd";
import PagePanel from "../ui/Panel/PagePanel";
import PanelBlock from "../ui/Panel/PanelBlock";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import MatchingColumnsBlock, { mocksColumns } from "./MatchingColumnsBlock/MatchingColumnsBlock";
import PanelLayout from "components/ui/Panel/PanelLayout";

import css from "./style.module.css";
import { ColumnType, PostXLSConfigRequestParseType } from "importTask/parseTypes";
import { AppStateType } from "../../redux/reducers/mainReducer";

const MatchingTableColumns: FC = () => {
  const [form] = Form.useForm<any>();

  const values = useWatch([], form);
  const { taskId } = useParams();

  const importTask: InitialImportTaskStateType = useSelector(
    (state: AppStateType) => state.importTaskSlice
  );

  const handleSubmit = (): void => {
    const columns: ColumnType[] = mocksColumns.map(({ name }, index) => {
      const type: string = values[`columnType-${index}`]?.value;

      return { name, type: type ?? "", is_identifier: !!values[`identifier-${index}`] };
    });

    const dataForSubmit: PostXLSConfigRequestParseType = {
      task_id: +taskId,
      name: values.taskName,
      first_line_skip: !!values.firstLineSkip,
      columns
    };

    postXLSConfig(dataForSubmit)
      .then((res) => {
        console.log(res);
        message.success("Изменения успешно сохранены");
      })
      .catch(console.error);
  };

  const renderHeader = (): JSX.Element => {
    return (
      <PanelLayout
        title={{ content: "Сопоставление колонок таблицы", size: "x-large" }}
        actions={{ content: <Button>Отменить</Button> }}
      >
        <PagePanel>
          <div className="flex justify-between">
            <PanelBlock title={{ content: "Название задачи сопоставления", isRequired: true }} >
              <Form.Item name="taskName">
                <Input
                  placeholder="Введите название задачи"
                  required
                />
              </Form.Item>
            </PanelBlock>
            <PanelBlock title={{ content: "Файл таблицы" }}>
              {/*TODO когда появится реальная ссылка, сделать скачивание файлы*/}
              <a href={importTask.xls.fileLink} className={css.link}>Загруженный файл</a>
            </PanelBlock>
          </div>
        </PagePanel>
      </PanelLayout>
    );
  };

  const renderFooter = (): JSX.Element => {
    return (
      <PagePanel>
        <div className="flex justify-end">
          <Button className="mr-2">Отменить и удалить</Button>
          <Button className="mr-2">Сохранить как черновик</Button>
          <PrimaryButton
            text="Подтвердить сопоставление"
            size="middle"
            isDisabled={!values?.taskName}
            onClickHandler={handleSubmit}
          />
        </div>
      </PagePanel>
    );
  };

  return (
    <Form form={form}>
      {renderHeader()}
      <MatchingColumnsBlock form={form} />
      {renderFooter()}
    </Form>
  );
};

export default MatchingTableColumns;
