import { FC, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useCancelableDebounce from "utils/useCancelableDebounce";

import { AppDispatch } from "redux/store/store";

import { SETTINGS_TYPE } from "./ImportYML";

import PageWrapper from "components/ui/PageWrapper";
import PagePanel from "../../components/ui/Panel/PagePanel";
import PanelBlock from "../../components/ui/Panel/PanelBlock";
import PanelLayout, { IPanelLayoutProps } from "../../components/ui/Panel/PanelLayout";
import ImportModePanel, { ImportModeType } from "./ImportModePanel";
import { Spin, Select, Radio, message, Tooltip, Button, Form, RadioChangeEvent } from "antd";

import { getWarehouses } from "redux/thunks/warehouses.thunk";
import { AppStateType } from "redux/reducers/mainReducer";
import { ImportTaskUploadSettingsType, XLSFileRequestType } from "importTask/types";
import { createXlsImportTask } from "redux/thunks/importTask.thunk";

type FormType = {
  warehouse: string;
  source?: ImportModeType;
  settings?: string[];
  fileField?: any;
  fileLink?: string;
  name?: string;
  login?: string;
  password?: string;
};

interface IImportMaster { };

const ImportMaster: FC<IImportMaster> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const [form] = Form.useForm<FormType>();
  const { getFieldsValue } = form;

  const {
    warehousesSlice: {
      list: warehousesList,
      isFetching: isLoadingWarehouses
    }
  } = useSelector((state: AppStateType) => state);

  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Добавление каталога", size: "x-large" },
    description: {
      content: "Выберите куда хотите загрузить каталог, затем выберите вариант импорта и следуйте инструкциям"
    },
    actions: { content: <Button size="large" type="default" onClick={(): void => { }}>Отменить</Button> }
  };

  const [importMode, setImportMode] = useState<ImportModeType>("yml");
  const [selectedWarehouse, setSelectedWarehouse] = useState<number | null>(null);

  const params: URLSearchParams = useMemo(() => new URLSearchParams(location.search), []);

  useEffect(() => {
    const mode: ImportModeType = params.get("mode") as ImportModeType;
    fetchWarehouses({});

    if (mode) setImportMode(mode);
  }, [params]);

  const handleError = (messageText: string, error: any): void => {
    message.error(messageText);
    console.error(error);
  };

  const { debouncedFunction: fetchWarehouses } = useCancelableDebounce((_params, token) => {
    dispatch(getWarehouses(token))
      .catch((error) => {
        handleError("Ошибка при загрузке списка складов.", error);
      });
  });

  const handleChangeImportMode = (e: RadioChangeEvent): void => {
    setImportMode(e.target.value);
  };

  const handleSelectWarehouse = (value: number): void => {
    setSelectedWarehouse(value);
  };

  const getUploadSettings = (): ImportTaskUploadSettingsType => {
    const { settings } = getFieldsValue();

    return {
      isPeriodic: settings?.includes(SETTINGS_TYPE.isPeriodic),
      updateRest: settings?.includes(SETTINGS_TYPE.updateRest),
      updatePrice: settings?.includes(SETTINGS_TYPE.updatePrice),
      updateProperty: settings?.includes(SETTINGS_TYPE.updateProperty),
      clearProducts: settings?.includes(SETTINGS_TYPE.clearProducts),
      firstLineSkip: settings?.includes("first_line_skip"),
      authorization: settings?.includes(SETTINGS_TYPE.logIn) ? {
        login: form.getFieldValue("login") ?? "",
        password: form.getFieldValue("password") ?? "",
      } : null,
    };
  };

  const getRequestBody = (): XLSFileRequestType => {
    const { ...values } = getFieldsValue();
    const isPeriodic: boolean = !!getUploadSettings()?.isPeriodic;

    const isFromFile: boolean = !isPeriodic;

    // TODO: Подключить актуальные данные как будут данные
    const requestBody: XLSFileRequestType = {
      ...getUploadSettings(),
      agreement: 0,
      warehouse: selectedWarehouse,
      vendorCatalog: 0,
    };

    if (isFromFile) {
      requestBody.name = values?.name;
      requestBody.tempDocumentId = 0;
    };

    return requestBody;
  };

  const handleCreateTask = (): void => {
    const isPeriodic: boolean = !!getUploadSettings()?.isPeriodic;

    const showMessage = (type: "error" | "success", error?: any): void => {
      const successMessage: string = isPeriodic ? "Периодическая задача" : "Задача";
      const errorMessage: string = isPeriodic ? "периодической задачи" : "задачи";

      if (type === "success") {
        message.success(`${successMessage} успешно создана`);
      } else {
        handleError(`Ошибка при создании ${errorMessage}`, error);
      }
    };

    dispatch(createXlsImportTask(getRequestBody()))
      .then(() => showMessage("success"))
      .catch((error: any) => showMessage("error", error));
  };

  return (
    <PageWrapper>
      <PanelLayout {...PANEL_LAYOUT_PROPS}>
        {/* TODO: Как будет апи поменять логику на isLoadingWarehouse */}
        <Spin tip="Склады загружаются" size="default" spinning={isLoadingWarehouses}>
          <PagePanel>
            <Form form={form} className="flex flex-col md:flex-row gap-4">
              <PanelBlock title={{ content: "Выберите вариант импорта" }}>
                <Radio.Group value={importMode} onChange={handleChangeImportMode}>
                  <Tooltip title="Добавьте продукты, используя прейскурант в Excel (XLS, CSV)">
                    <Radio.Button value="xlsx">Import Excel</Radio.Button>
                  </Tooltip>
                  <Tooltip title="Публикация продуктов с использованием формата YML, Yandex.Market или Commerce-ML">
                    <Radio.Button value="yml">Import YML</Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </PanelBlock>

              <PanelBlock title={{ content: "Выбор склада" }}>
                <Form.Item name="warehouse" className="w-full m-0">
                  <Select
                    placeholder="Список складов"
                    value={selectedWarehouse}
                    onSelect={handleSelectWarehouse}
                    disabled={!warehousesList.length}
                    notFoundContent={isLoadingWarehouses ? <Spin spinning={isLoadingWarehouses} /> : null}
                    className="w-full"
                  >
                    {warehousesList.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </PanelBlock>
            </Form>
          </PagePanel>
        </Spin>

        {/* TODO: Как будет апи поменять логику на !selectedWarehouse */}
        <Spin tip="Выберите склад" size="default" spinning={!!selectedWarehouse}>
          <ImportModePanel form={form} importMode={importMode} />
        </Spin>

        <PagePanel className="apply_wrapper flex justify-end">
          <Button type="primary" size="large" onClick={handleCreateTask}>
            Загрузить каталог
          </Button>
        </PagePanel>
      </PanelLayout>
    </PageWrapper>
  );
};

export default ImportMaster;