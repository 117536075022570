import { createSlice } from "@reduxjs/toolkit";
import { ImportTaskType, TaskColumnType } from "importTask/types";
import { SelectOptionType } from "app/types";

type DefautDataStateType = {
  error: string | null;
  isFetching: boolean;
};

interface IXLSColumns extends DefautDataStateType {
  list: SelectOptionType[];
}

interface IXLSTaskColumns extends DefautDataStateType {
  list: TaskColumnType[];
}

interface IXLSState extends DefautDataStateType {
  list: any[];
  columns: IXLSColumns;
  taskColumns: IXLSTaskColumns;
  taskId: number;
  fileLink: string;
}

interface IYMLState extends DefautDataStateType {
  list: any[];
}

export type InitialImportTaskStateType = {
  error: string | null;
  isFetching: boolean;
  count: number;
  list: ImportTaskType[];
  yml: IYMLState;
  xls: IXLSState;
}

const initialState: InitialImportTaskStateType = {
  error: null,
  isFetching: false,
  count: 0,
  list: null,
  yml: {
    list: null,
    error: null,
    isFetching: false,
  },
  xls: {
    list: null,
    error: null,
    isFetching: false,
    columns: {
      list: null,
      error: null,
      isFetching: false,
    },
    taskColumns: {
      list: null,
      error: null,
      isFetching: false,
    },
    taskId: null,
    fileLink: null,
  },
};

const importTaskSlice = createSlice({
  name: "importTask",
  initialState,
  reducers: {
    /** Загрузка файла XLS */
    createXLSImportTaskRequest(state, _action) {
      state.xls.error = null;
      state.xls.isFetching = true;
    },
    createXLSImportTaskSuccess(state, action) {
      state.xls.error = null;
      state.xls.isFetching = false;
      state.xls.list = [...state.xls.list, action.payload];
      state.xls.taskId = action.payload.taskId;
      state.xls.fileLink = action.payload.fileLink;
      state.xls.taskColumns = action.payload.columns;
    },
    createXLSImportTaskFailure(state, _action) {
      state.xls.error = null;
      state.xls.isFetching = false;
    },

    /** Получение списка колонок */
    fetchXLSConfigColumnsRequest(state) {
      state.xls.columns.error = null;
      state.xls.columns.isFetching = true;
    },
    fetchXLSConfigColumnsSuccess(state, action) {
      state.xls.columns.list = action.payload;
    },
    fetchXLSConfigColumnsFailure(state, action) {
      state.xls.columns.error = action.payload;
      state.xls.columns.isFetching = false;
    },

    /** Получение списка задач на импорт из файла */
    fetchAllImportTasksRequest(state) {
      state.error = null;
      state.isFetching = true;
    },
    fetchAllImportTasksSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.count = action.payload.count;
      state.list = action.payload.results;
    },
    fetchAllImportTasksFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },

    /** Очистка state */
    clearImportTaskStateSuccess(state) {
      state.xls.taskId = null;
      state.xls.fileLink = null;
      state.xls.taskColumns.list = [];
    }
  }
});

const { actions, reducer } = importTaskSlice;

export const {
  createXLSImportTaskRequest,
  createXLSImportTaskSuccess,
  createXLSImportTaskFailure,

  fetchXLSConfigColumnsRequest,
  fetchXLSConfigColumnsSuccess,
  fetchXLSConfigColumnsFailure,

  fetchAllImportTasksRequest,
  fetchAllImportTasksSuccess,
  fetchAllImportTasksFailure,

  clearImportTaskStateSuccess
} = actions;

export default reducer;
