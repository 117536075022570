import React, { FC, JSX, useEffect } from "react";
import { fetchXLSConfigColumns } from "../../redux/thunks/importTask.thunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import MatchingTableColumns from "../../components/MatchingTableColumns/MatchingTableColumns";

import css from "./style.module.css";

const MatchingTableColumnsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchXLSConfigColumns());
  }, []);

  return (
    <div className={`${css.wrapper} p-4`}>
      <MatchingTableColumns />
    </div>
  );
};

export default MatchingTableColumnsPage;
