import React, { FC, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { SelectOptionType } from "app/types";
import { TaskColumnType } from "importTask/types";

// components
import { Checkbox, Form, FormInstance, Select, Table } from "antd";
import Column from "antd/es/table/Column";
import PanelBlock from "../../ui/Panel/PanelBlock";

interface IMatchingColumnProps {
  form: FormInstance<any>;
  column: TaskColumnType;
  index: number;
  selectOptions: SelectOptionType[];
}

const MatchingColumn: FC<IMatchingColumnProps> = ({
  form,
  column,
  index,
  selectOptions
}) => {
  const { name, values } = column ?? {};

  const [selectedValue, setSelectedValue] = useState<SelectOptionType>(null);

  const data: Record<string, string>[] = values?.map((value: string, idx: number) => {
    return ({ key: idx.toString(), name: value });
  });

  const handleSelect = (option: SelectOptionType): void => {
    setSelectedValue(option);
  };

  const handleCheck = (e: CheckboxChangeEvent): void => {
    form.setFieldsValue({ [`identifier-${index}`]: e.target.checked });
  };

  return (
    <div className="flex justify-between flex-1 mb-8">
      <Table<Record<string, string>> dataSource={data} pagination={false} className="mr-4 basis-2/4">
        <Column title={name} dataIndex="name" />
      </Table>
      <PanelBlock
        title={{ content: "Тип колонки" }}
        wrapper={{ className: "basis-2/4" }}
      >
        <Form.Item name={`columnType-${index}`} className="mb-0">
          <Select
            allowClear
            value={selectedValue}
            options={selectOptions}
            labelInValue
            onChange={(_value, option: SelectOptionType) => handleSelect(option)}
          />
        </Form.Item>
        <Form.Item name={`identifier-${index}`}>
          <Checkbox onChange={handleCheck}>Идентификатор</Checkbox>
        </Form.Item>
      </PanelBlock>
    </div>
  );
};

export default MatchingColumn;
