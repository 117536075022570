import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import * as ImportTaskAPI from "../../api/importTask";
import { SelectOptionType } from "app/types";
import {
  GetAllImportTasksRequestParseType,
  XLSFileRequestParseType
} from "importTask/parseTypes";
import {
  createXLSImportTaskFailure,
  createXLSImportTaskRequest,
  createXLSImportTaskSuccess,
  fetchAllImportTasksFailure,
  fetchAllImportTasksRequest,
  fetchAllImportTasksSuccess,
  fetchXLSConfigColumnsFailure,
  fetchXLSConfigColumnsRequest,
  fetchXLSConfigColumnsSuccess
} from "../slices/importTaskSlice";
import {
  XLSConfigColumnsResponseType,
  XLSFileRequestType,
  XLSFileResponseType
} from "importTask/types";
import { CancelToken } from "axios";
import { toCamelCase } from "utils/formatData";

export const createXlsImportTask = (
  params: XLSFileRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<XLSFileResponseType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createXLSImportTaskRequest(params));
  try {
    const formattedRequest: XLSFileRequestParseType = toCamelCase(params);

    const response = await ImportTaskAPI.postImportTaskXLSFile(formattedRequest, cancelToken);
    const formattedResponse: XLSFileResponseType = toCamelCase(response);

    dispatch(createXLSImportTaskSuccess(response?.data));

    return Promise.resolve(formattedResponse);
  } catch (error: any) {
    dispatch(createXLSImportTaskFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};

export const fetchXLSConfigColumns = (): ThunkAction<
  Promise<Record<string, string>[]>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchXLSConfigColumnsRequest());

  try {
    // TODO вернуть когда ручка заработает
    // const response = await ImportTaskAPI.getXLSConfigColumns();
    // TODO как заработают ручки, удалить моки, вместо них передавать response?.data
    const mocksColumnList: XLSConfigColumnsResponseType[] = [
      { type: "name", name: "Название продукта" },
      { type: "code", name: "Артикул продукта" },
      { type: "supplier_code", name: "Код производителя" },
      { type: "rate_nds", name: "Ставка НДС" },
      { type: "description", name: "Описание продукта" },
      { type: "min_count", name: "Минимальное количество для заказа" },
      { type: "index", name: "Свойство" },
      { type: "source", name: "Изображения" },
      { type: "supplier_category_name", name: "Категория поставщика" },
      { type: "rest", name: "Остатки" },
      { type: "currency_price_USD", name: "Цена (Доллар США)" },
      { type: "currency_price_EUR", name: "Цена (Евро)" },
      { type: "currency_price_RUB", name: "Цена (Российский рубль)" },
    ];

    const formattedColumnList: SelectOptionType[] = mocksColumnList
      ?.map(({ type, name }) => ({ value: type, label: name }));

    dispatch(fetchXLSConfigColumnsSuccess(formattedColumnList));

    return Promise.resolve(mocksColumnList);
  } catch (error: any) {
    dispatch(fetchXLSConfigColumnsFailure(error));

    return Promise.reject(error);
  }
};

/** Получение списка задач на импорт из файла */
export const fetchAllImportTasks = (params: GetAllImportTasksRequestParseType): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchAllImportTasksRequest());
  try {
    const response = await ImportTaskAPI.getAllImportTasks(params);
    const formattedResponse = toCamelCase(response?.data);

    dispatch(fetchAllImportTasksSuccess(formattedResponse));
  } catch (error: any) {
    dispatch(fetchAllImportTasksFailure(error?.response?.data?.error));
  }
};
