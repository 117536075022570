import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import {
  XLSFileRequestParseType,
  XLSFileResponseParseType,
  PostXLSConfigRequestParseType,
  XLSConfigResponseParseType,
  GetAllImportTasksRequestParseType,
  GetAllImportTasksResponseParseType,
} from "importTask/parseTypes";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Импорт excel ****/ }

/** Загрузка файла XLS */
export const postImportTaskXLSFile = (
  body: XLSFileRequestParseType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<XLSFileResponseParseType>> => {
  return axios.POST(API_BASE, "/api/v1/import_task/xls/file", body, cancelToken);
};

/** Сохранение настроек загружаемого файла XLS */
export const postXLSConfig = (
  body: PostXLSConfigRequestParseType
): Promise<AxiosResponse<XLSConfigResponseParseType>> => {
  return axios.POST(API_BASE, "/api/v1/import_task/xls/config", body);
};

/** Получение списка колонок для сопоставления колонок загружаемого файла */
export const getXLSConfigColumns = (): Promise<AxiosResponse<XLSConfigResponseParseType>> => {
  return axios.GET(API_BASE, "/api/v1/import_task/xls/config/columns");
};

/** Получений списка задач на импорт из файла */
export const getAllImportTasks = (params?: GetAllImportTasksRequestParseType): Promise<
  AxiosResponse<GetAllImportTasksResponseParseType>
> => {
  return axios.GET(API_BASE, "/api/v1/import_task/all", params);
};
