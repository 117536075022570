import { FC, ReactNode } from "react";

export interface IPanelBlockProps {
  title?: {
    content?: ReactNode;
    className?: string;
    isRequired?: boolean;
  };
  wrapper?: {
    className?: string;
  }
  className?: string,
  children?: ReactNode;
}

const PanelBlock: FC<IPanelBlockProps> = ({
  title = null,
  wrapper = null,
  className,
  children,
}) => (
  <div
    className={`flex flex-col ${wrapper?.className ?? "w-full grow-1 md:w-0 md:grow-1/2 xl:grow-1/3"}`}
  >
    {title && (
      <div className="header_wrapper">
        <p className={title?.className ?? "header_text"}>
          {!!title?.isRequired && <span className="text-red-600 mr-1">*</span>}
          {title?.content}
        </p>
      </div>
    )}
    <div className={`content_wrapper flex flex-col ${className ?? ""}`}>
      {children}
    </div>
  </div>
);

export default PanelBlock;
