import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";

import { UploadFile } from "antd/lib/upload/interface";
import { TempDocumentParseType } from "tempDocuments/parseTypes";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Временный документ ****/ }

/** Создание временного документа */
export const postTempDocument = (
  file: File | UploadFile,
  cancelToken?: CancelToken
): Promise<AxiosResponse<TempDocumentParseType>> => {
  return axios.POST(API_BASE, `/api/v1/temp_documents/create`, file, cancelToken);
};

/** Получение временного документа */
export const getTempDocument = (
  tempDocumentId: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<TempDocumentParseType>> => {
  return axios.GET(API_BASE, `/api/v1/queues/temp_documents/${tempDocumentId}`, null, cancelToken);
};